body {
  line-height: 1.7 !important;
}

$border-radius-lg: 0.85rem;
$theme-colors: (
  "primary": #6bcad0,
  "secondary": #c9f7f5,
  "info": #ffa800,
  "danger": #f64e60
);
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&&family=Yanone+Kaffeesatz:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;400;500;600;700;800;900&display=swap");
.Playfair {
  font-family: "Playfair" !important;
}

.poppins {
  font-family: "Poppins" !important;
}

.poppins-400 {
  font-family: "Poppins" !important;
  font-weight: 400;
}

.popins-700 {
  font-family: "Poppins";
  font-weight: 700;
}

.Yanone_Kaffeesatz {
  font-family: "Yanone Kaffeesatz";
  font-weight: 400;
}

.Yanone_Kaffeesatz-700 {
  font-family: "Yanone Kaffeesatz";
  font-weight: 700;
}

.f-55 {
  font-size: 55px;
}

.f-50 {
  font-size: 50px;
}

.f-45 {
  font-size: 45px;
}

.f-32 {
  font-size: 32px;
}

.f-35 {
  font-size: 35px;
}

.f-25 {
  font-size: 25px;
}

.f-40 {
  font-size: 40px;
}

.p-17 {
  font-size: 17px;
}

/* -------------Default--------------------- */

.primary-color {
  color: #6bcacf;
}

.primary-blue {
  color: #035a94;
}

.primary-pink {
  color: #f69494;
}

.secondary-blue {
  color: #035a94;
  // #1e4363;
}

.programs-blue {
  color: #1e4363;
}

.dashboard-blue {
  color: #0f3d67;
}

.secondary-b {
  color: #282828 !important;
}

p {
  color: #282828;
  font-size: 20px;
}

.NavLink {
  padding: 8px 0 8px 0 !important;
}

.w-95 {
  width: 95%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-43 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-45 {
  width: 45%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mt-10 {
  margin-top: 6rem !important;
}

.mt-15 {
  margin-top: 15em;
}

.mb-10 {
  margin-bottom: 6rem !important;
}

.ml-10 {
  margin-top: 6rem;
}

.mr-10 {
  margin-top: 6rem;
}

.pt-lg-0 {
  padding-top: 6rem;
}

.pt-10 {
  padding-top: 6rem;
}

.pt-lg-11 {
  padding-top: 11rem;
}

.pt-lg-10 {
  padding-top: 10rem;
}

.pt-20 {
  padding-top: 12rem !important;
}

.pb-10 {
  padding-bottom: 6rem;
}

.ps-10 {
  padding-left: 6rem;
}

.pe-10 {
  padding-right: 6rem;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row {
  margin: auto 0px !important;
}

.imgResponsive {
  width: 80%;
}

// .secondary-b {
//   color: #656565 !important;
// }
.h-80 {
  height: 80%;
}

.h-60 {
  height: 60%;
}

.h-50 {
  height: 50%;
}

.h-40 {
  height: 40%;
}

.h-45 {
  height: 45%;
}

.h-30 {
  height: 30%;
}

.h-25 {
  height: 25%;
}

.h-20 {
  height: 20%;
}

.h-15 {
  height: 15%;
}

.h-10 {
  height: 10%;
}

.my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.navhover:hover {
  color: #f69494 !important;
}

.current {
  color: #df3e23;
}

.bg_img_a {
  background-image: url("./assets/images/banner_bg.png");
  background-repeat: no-repeat, repeat;
  background-position: top right;
}

.bg_quote {
  background-image: url("./assets/images/quote.png");
  background-repeat: no-repeat, repeat;
  background-position: 70% 30%;
}

.starter-kit {
  padding: 70px 48px;
  background-image: url("./assets/images/dashboard_Asset0.png");
  background-repeat: no-repeat, repeat;
  background-position: bottom right;
  background-size: 50%;
}

.bg_img_b {
  background-image: url("./assets/images/bg_img_b.png");
  background-repeat: no-repeat, repeat;
  background-position: left;
}

.yt_thumbnail_box {
  width: 100%;
  border-radius: 30px;
}

.dashboard_bg_img {
  background-image: url("./assets/images/dashboard_Asset1.png");
  background-repeat: no-repeat, repeat;
  background-position: left;
}

.footer_bg_img {
  background-image: url("./assets/images/footer.png");
  background-repeat: no-repeat, repeat;
  background-position: bottom;
  background-size: 100% 71%;
}

.navbar_style {
  text-decoration: none;
  color: "#000000";
  font-size: "18px";
}

.carousel-indicators {
  padding-top: 3em !important;
}

.card-height {
  height: 500px;
}

.mt-180 {
  margin-top: 180px;
}

.mb-lg-10 {
  margin-bottom: 6rem;
}

.program_cards {
  width: 396px;
  height: 920px;
}

@media (max-width: 866px) {
.programsfilter{
  display: flex;
  flex-direction: column;
  align-items: center;
}
}

@media (max-width: 540px) {
  .f-45 {
    font-size: 32px;
  }
  .mt-180 {
    margin-top: 85px;
  }
  .color-h3 {
    font-size: 19px;
  }
  .mb-lg-10 {
    margin-bottom: 3rem;
  }
  .mt-3e {
    margin-top: 3em !important;
  }
  .mt-15 {
    margin-top: 6rem;
  }
  .pt-lg-11 {
    padding-top: 0;
  }
  .pt-lg-10 {
    padding-top: 3rem;
  }
  .fs-5 {
    font-size: 16px !important;
  }
  .f-40,
  .fs-1 {
    font-size: 31px !important;
  }
  .f-35 {
    font-size: 29px;
  }
  .f-32 {
    font-size: 19px !important;
  }
  .f-50 {
    font-size: 35px;
  }
  .p-17 {
    font-size: 15px;
  }
  .f-25 {
    font-size: 19px;
  }
  .pt-10 {
    padding-top: 3rem !important;
  }
  .pt-11 {
    padding-top: 11rem !important;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  p {
    color: #282828 !important;
    font-size: 16px;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-60 {
    width: 60%;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-47 {
    width: 47%;
  }
  .w-sm-40 {
    width: 40%;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-25 {
    width: 25%;
  }
  .w-sm-20 {
    width: 20%;
  }
  .w-45 {
    width: 100%;
  }
  .w-48 {
    width: 100%;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .card-height {
    height: 380px;
  }
  .enrollmentCard {
    top: 0px !important;
  }
  .carousel-control-prev {
  }
}

.accordion-item {
  border-radius: 10px !important;
  background: #ffffff;
  border: none !important;
  // padding: 12px 20px;
  box-shadow: 0px 8px 12px rgb(0 0 0 / 10%);
  margin-bottom: 15px;
}

.bg-img-about {
  background-image: url("./assets/images/Asset0.png");
  background-repeat: no-repeat;
  background-size: 76%;
  background-position: right top;
}

.cloudBg {
  background-image: url("./assets/images/cloud_1.png");
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: center;
}

.cloud_2Bg {
  background-image: url("./assets/images/cloud_2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.bg-img-programs {
  background-image: url("./assets/images/programs_bg.png");
  padding-top: 6em;
  // padding-bottom: 6em;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: top left;
  margin-top: -30px;
  // background-position:left top;
}

.bg-img-pfb {
  background-image: url("./assets/images/pfb_bg.png");
  padding-top: 6em;
  padding-bottom: 6em;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center right;
  // background-position:left top;
}

.bg-img-pfg {
  background-image: url("./assets/images/pfg_bg.png");
  padding-top: 6em;
  padding-bottom: 6em;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center right;
  // background-position:left top;
}

.bg-img-learn {
  background-image: url("./assets/images/learn.png");
  padding-top: 6em;
  padding-bottom: 2em;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-position: center right;
  // background-position:left top;
}

.enrollmentCard {
  position: absolute;
  top: 97px;
  right: 0%;
}

.cart_number {
  width: 21px;
  background-color: #f64e60;
  border-radius: 50px;
  padding: 3px;
  color: #fff;
  font-size: 10px;
}

.accordion-item .accordion-button {
  font-size: 17px !important;
}

// video-play-button animations
.video-play {
  transform: scale(1);
  animation: pulse-blue 2s infinite;
  cursor: pointer;
}

.video-play .blue_shadow {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(52, 172, 224, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 12px rgba(52, 172, 224, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px 8px rgba(52, 172, 224, 0);
  }
}

.ul {
  margin: 0;
}

ul.affiliate_list {
  list-style-type: none;
}

ul.affiliate_list > li {
  text-indent: -5px;
}

ul.affiliate_list > li:before {
  content: "-";
  text-indent: -5px;
  margin-right: 10px;
}

/* --------------iPad-Pro------------------- */

@media (max-width: 1202px) {
  .imgResponsive {
    width: 100%;
  }
  .bg-img,
  .bg_img_b,
  .bg-img-about,
  .bg-img-programs {
    background-image: none;
  }
  .enrollmentCard {
    position: relative;
    top: 32%;
    right: 0%;
  }
}

//   ---------------circular progress-bar(Dashboard)----------------
$borderWidth: 5px;
$animationTime: 0.5s;
$border-color-default: rgba(255, 244, 222, 0.85);
$border-color-fill: #464e5f;
$size: 160px;
$howManySteps: 100; //this needs to be even.
.progress {
  width: 120px;
  height: 120px;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/

@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));
  //animation only the left side if below 50%
  @if $i <=($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }
  //animation only the right side if above 50%
  @if $i>($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
      }
      .progress-left .progress-bar {
        animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);
  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}

// --------------------footer----------------------
.footer-icons a {
  width: 40px;
  height: 40px;
  background-color: #6bcad0;
  color: #fff !important;
}

.footer-icons > FaFacebook {
  color: #fff;
}

.footer-icons a:hover {
  color: black !important;
  background-color: #00dbde;
  cursor: pointer;
}

// ------WhatsApp Chat---------------------

/* for desktop */

.whatsapp_float {
  position: fixed;
  bottom: 80px;
  right: 10px;
  padding: 15px;
  line-height: 0px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

._whatsapp_float {
  position: fixed;
  bottom: 20px;
  right: 10px;
  padding: 25px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 0px;
  background-color: #f69494;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

._whatsapp_float:hover {
  color: #fff;
}

.whatsapp-icon {
  margin-top: 16px;
}

// /* for mobile */
// @media screen and (max-width: 767px) {
//     .whatsapp-icon {
//         margin-top: 10px;
//     }
//     .whatsapp_float {
//         width: 40px;
//         height: 40px;
//         bottom: 20px;
//         right: 10px;
//         font-size: 22px;
//     }
// }
.audience-group {
  padding: 5px;
  margin: 0 0 15px 0;
  cursor: pointer;
}

.audience-group-checkbox {
  margin: 0 10px 0 0;
  float: left;
}

.audience-group-name {
  cursor: pointer;
  float: left;
  font-size: 13px;
  font-weight: bold;
}

.audience {
  padding: 5px;
  margin: 0 0 15px 25px;
  cursor: pointer;
  display: none;
}

.audience-checkbox {
  margin: 0 10px 0 0;
  float: left;
}

.audience-name {
  float: left;
  font-size: 13px;
}

.carousel-indicators {
  margin-bottom: 0em !important;
  padding-bottom: 20px !important;
}

// .dropdown:hover>.dropdown-menu {
//   display: block;
// }
// .dropdown:hover>.dropdown-menu.show {
//   display: block;
// }
.dropdown-menu.show:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 20%;
  top: -26px;
  border: 10px dotted transparent;
  border-bottom: 20px dotted white;
}

.dropdown-menu {
  padding: 0px 0px !important;
}

// video-play
.video-play {
  background-color: #6bcacf;
  width: 5%;
  height: 37%;
  left: 20%;
  top: -9%;
}

@media only screen and (max-width: "540px") {
  .bg-img-learn-sm {
    background-image: url("./assets/images/mob_learnbg.png");
    padding-top: 6em;
    padding-bottom: 2em;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-position: center right;
    // background-position:left top;
  }
  .bg-img-pfb {
    padding-top: 3em !important;
  }
  .flex-sm-column-reversee {
    flex-direction: column-reverse;
  }
  .f-sm-38 {
    font-size: 38px !important;
  }
  .video-play {
    background-color: rgb(107, 202, 207);
    width: 17%;
    height: 37%;
    left: 75%;
    top: -9%;
  }
  .footer_bg_img {
    background-size: 661% 41%;
  }
  .carousel-control-prev {
    // margin-left: -17px!important;
    width: 22px;
  }
  .carousel-control-next {
    // margin-right: -17px!important;
    width: 22px;
  }
}

.hidden {
  display: none !important;
}

.p-cursor {
  cursor: pointer;
}

.processactive {
  background-color: rgb(107, 202, 207);
}

.dropdown-menu a:hover {
  background-color: #1e4363;
  color: #f69494 !important;
}

// .active{
//   color:#1e4363;
// }
@media (max-width: 1536px) {
  .enrollmentCard {
    top: 211px;
  }
  .mxh-500 {
    max-height: 490px;
  }
}

@media (max-width: 1337px) {
  .enrollmentCard {
    top: 292px;
  }
}

@media (max-width: 990px) {
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 10rem;
  height: 4rem;
}

.learn .carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
}

.text-gray-500 {
  color: #6b7280 !important; // Tailwind's gray-500 color
}

.image-container {
  width: 80px; // Desired size of the circular image
  height: 80px; // Same as width to make the container square
  overflow: hidden; // Ensures any overflow content is hidden
  border-radius: 50%; // Makes the container a circle
  display: flex; // Centers the image within the container
  align-items: baseline; // Vertically centers the image
  justify-content: center; // Horizontally centers the image
  background-color: #f69494;
}

.circle-image {
  width: 100%; // Ensures the image covers the entire container
  height: auto; // Maintains the image's aspect ratio
  object-fit: cover; // Ensures the image covers the entire container area without distortion
  object-position: top;
}

.rounded-more {
  border-radius: 21px; // Increase border-radius for more rounded corners
}


.responsive-img {
  max-width: 100%; // Ensure the image does not exceed the width of its parent container
  height: auto; // Maintain aspect ratio

  @media (min-width: 576px) {
    width: 100%; // For small devices and above, set width to 100% of its container
  }

  @media (min-width: 768px) {
    width: 80%; // For medium devices and above, set width to 80% of its container
  }

  @media (min-width: 992px) {
    width: 70%; // For large devices and above, set width to 70% of its container
  }

  @media (min-width: 1200px) {
    width: 100%; // For extra large devices and above, set width to 60% of its container
  }
}

.carousel {
  margin-top: 4rem; /* mt-16 */
  width: 80%; /* w-4/5 */
  margin: auto; /* m-auto */
  row-gap: 3rem; /* gap-y-12 */
  color: white; /* text-white */
}

@media (min-width: 640px) { /* sm */
  .element {
    width: 75%; /* sm:w-9/12 */
  }
}

@media (min-width: 768px) { /* md */
  .element {
    width: 91.6667%; /* md:w-11/12 */
  }
}


// .carousel div {
//   padding: 0.5rem; /* p-2 */
//   background-color: #E4A795; /* bg-[#E4A795] */
//   width: 100%; /* w-full */
//   color: black; /* text-black */
//   border-radius: 0.75rem; /* rounded-xl */
//   display: flex; /* d-flex */
//   flex-direction: row; /* flex-row */
//   justify-content: center; /* justify-center */
// }

// .carousel div div {
//   display: flex; /* d-flex */
//   flex-direction: column; /* flex-col */
//   margin-top: auto; /* my-auto */
//   margin-bottom: auto; /* my-auto */
//   text-align: center; /* text-center */
// }

// @media (min-width: 1280px) { /* xl */
//   .element {
//     flex-direction: row; /* xl:flex-row */
//   }
// }


.title-header {
  font-weight: 600;
  font-size: 45px;
  line-height: 65px;
  letter-spacing: -2%;
}


h1 {
  font-size: 59.44px;
}


// * Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .title-header {
        font-size: 35px;
        line-height: 50px;
        letter-spacing: -1.5%;
    }

    h1 {
        font-size: 45px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .title-header {
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -1%;
    }

    h1 {
        font-size: 35px;
    }
}


.text-pink{
  color: #eb106f;
}

.text-blue{
  color: #000080;
}

.text-light-blue{
  color: #00ccff;
}

.text-brown{
  color: #993300;
}


.react-calendar {
  border: none !important;
  border-radius: 10px !important;
  background:transparent !important;
  width: 100% !important;
  box-shadow: 2px 2px #e9e9e9 !important;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__next-button {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: 1px 2px #888888 !important;
  margin: 1% !important;
}

.react-calendar__navigation__prev-button {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: 1px 2px #888888 !important;
  margin: 1% !important;
}

.react-calendar__month-view__days__day {
  border-radius: 10px !important;
}

.bg_img_r {
  background-image: url("./assets/images/bg_img_b.png");
  background-repeat: no-repeat, repeat;
  background-position: right;
}

.react-calendar__tile {
  max-width: 100%;
  // padding: 10px 8px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile--range {
  background:transparent;
  color: #000;
  border-radius: 0;
  margin-top: 10px;
 }

.react-calendar__tile--rangeStart {
  background: #1087ff;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  background: #1087ff;
  color: white;
 }

 .react-calendar__month-view__days__day{
  border-radius: 100% !important;

 }
