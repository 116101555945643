// ContactForm
#moreoptions li::marker {
  font-size: 1px;
}
#inputbox li::marker {
  font-size: 1px !important;
}

.gap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}



/* // Learn Section Carousel */
.carousel-indicators {
  margin-bottom: 3em !important;
}
.learn .carousel-control-prev-icon {
  background-image: url("./assets/images/left.png") !important;
}
.learn .carousel-control-next-icon {
  background-image: url("./assets/images/left.png") !important;
  transform: scaleX(-1);
}
.learn .carousel-control-prev {
  left: -60px !important;
}
.learn .carousel-control-prev {
  left: -60px !important;
}
.learn .carousel-indicators [data-bs-target] {
  background-color: #204462 !important;
  // display: none !important;
}
.learn .carousel-indicators > button {
  height: 10px;
  width: 30px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  text-indent: 0;
  border-radius: 25px;
}

/* // Discuss Accordin Menu */

.discuss .accordion-flush .accordion-collapse {
  background-color: #bff2f1;
  font-weight: 600;
  color: #204462;
  text-align: center;
}
.discuss .accordion-flush .accordion-item .accordion-button {
  background-color: #6fcace;
  color: #204462;
  font-weight: 600;
  text-align: center;
}
.accordion-flush .accordion-item .accordion-button:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  outline: none !important;
}
.discuss .carousel-indicators {
  display: none !important;
}

/* // Play Section */

.play .showQuestion {
  background-color: #1e4260;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px 25px 25px 20px;
  border: 5px solid #000;
  padding: 10px 0 0 0;
}

@media screen and (min-width: 768px) {
  .play .showQuestion {
    padding: 40px;
    flex-direction: row;
    flex-flow: row wrap;
  }
}

.play .form-check {
  /* // background-color:#FA9EA3;
    // color:Black;
    // margin: 30px 0; */
  display: flex !important;
  flex-flow: row wrap;
  /* // justify-content:space-around; */
  align-items: center;
  /* // border-radius:20px 25px 25px 20px ;
    // border:2px solid #000;
    // height: 10vh;
    text-align: center; */
  margin-top: 40px;
  padding: 0 40px;
  position: relative;
}
.form-check-label {
  width: 80%;
}
.play .form-check:focus {
  background-color: #305879 !important;
  color: #fff;
  cursor: pointer;
}
.play .form-check .form-check-input {
  float: none !important;
  margin-left: 0 !important;
  cursor: pointer;
}
.play .form-check-input[type="radio"] {
  border-radius: 20px 25px 25px 20px !important;
  /* //  opacity: !important; */
  background-color: #fa9ea3 !important;
  min-height: 85px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.play .form-check-input[type="checkbox"] {
  border-radius: 20px 25px 25px 20px !important;
  /* //  opacity: !important; */
  background-color: #fa9ea3;
  min-height: 85px;
  width: 100%;
  position: relative;
}
.play .form-check-input:checked[type="checkbox"] ~ label {
  color: #fff !important;
}
.play .form-check-input:checked[type="radio"] ~ label {
  color: #fff !important;
}
.play .form-check-input {
  margin-top: 0 !important;
}
.play .form-check-input:checked[type="radio"] {
  background-color: #789fbe !important;
  background-image: none !important;
  border-color: #789fbe !important;
}
.play .form-check-input:checked[type="checkbox"] {
  background-color: #789fbe !important;
  background-image: none !important;
  border-color: #789fbe !important;
}
.play .form-check-label {
  color: black;
  font-weight: bold;
  position: absolute;
  left: 50%;
  font-size: 20px;
  transform: translate(-50%);
  cursor: pointer;
}
.play form button {
  padding: 8px 55px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-size: 20px !important;
  margin: 30px 0;
}
.scale_img{
 
    transition: all .2s ease-in-out; 
}
    .scale_img:hover { 
        transform: scale(1.5); 
      }



.navbar .nav-item:hover .dropdown-menu {
  transform: 2s ease-in !important;
}
.dropdown-menu:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 20%;
  top: -26px;

  border: 10px dotted transparent;
  border-bottom: 20px dotted white;
}
.dropdown-menu.show {
  padding: 0 !important;
}
.dropdown-item {
  padding: 0.9rem 1rem !important;
  box-shadow: "0 3px 10px rgb(0 0 0 / 20%)" !important;
  border-bottom: 1px solid #375064 !important;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
// about section
.cloudBg {
  position: absolute;
  top: 9px;
  left: 10px;
  margin-left: -51px
}
.cloudBg p {
  padding: 43px 99px 88px;

  margin: 40px 0px;
  // margin: 35px 0;
  font-weight: 600;
  font-size: 16px;
}
.cloud_2Bg {

  position: absolute;
  bottom: 50px;
  left: 34px;
  margin-left: -53px;
  width: 63%
}
.cloud_2Bg p {
  padding: 52px 50px 35px;
  width: 80%;
  margin: 35px 0;
  font-weight: 600;
  font-size: 16px;
}

@media only screen and (max-width: 540px) {
  .w-sm-40{
    width: 40%!important;
  }
  .cloudBg {
  

    position: absolute;
    top: -183px;
    left: 9px;
    margin-right: 10px;
    margin-left: 0;
  }
  .cloudBg p {
    padding: 29px 26px 52px;
    /* margin: 40px 0px; */
    font-weight: 600;
    font-size: 12px;
    
  }
  .cloud_2Bg p {
    padding: 45px 21px 44px;
    width: 80%;
    margin: 35px 0px;
    font-weight: 600;
    font-size: 12px;

    
  }
  .cloud_2Bg {
    position: absolute;
    bottom: -32px;
    font-size: 11px;
    left: 25px;
    margin-left: 0px;
    width: 60%;
  }
  .pr-20{
    padding-right: 20px;
  }
  .learn .carousel-control-prev {
    left: 0px !important;
  }
  .learn .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
    padding-right: 2px!important;
    padding-left: 2px!important;
  }
  .play .form-check-label{
    font-size: 16px;
  }
}

.programCards{
  gap: 2%;
}